<template>
  <div class="appointment-details">
    <SfHeading
      title="Book an appointment"
      :level="2"
      class="sf-heading--left sf-heading--no-underline title"
    />
    <div class="appointment-details__wrapper">
      <div class="personal-details card">
        <SfHeading
          title="APPOINTMENT FOR"
          :level="3"
          class="sf-heading--left sf-heading--no-underline"
        />
        <SfInput
          v-model="fullName"
          :value="fullName"
          label="Full Name"
          name="fullName"
          class="form__element"
          :required="true"
          :valid="!$v.fullName.$error"
          :error-message="$t('Field is required')"
          @blur="$v.fullName.$touch()"
        />
        <SfInput
          v-model="email"
          :value="email"
          label="Email"
          name="email"
          class="form__element"
          :required="true"
          :valid="!$v.email.$error"
          :error-message="$t('Field is required')"
          @blur="$v.email.$touch()"
        />
        <PhoneInput
          v-model="phone"
          :value="phone"
          label="Phone number"
          name="phone"
          class="form__element"
          :required="true"
          :valid="!$v.phone.$error"
          :error-message="
            !$v.phone.required
              ? $t('Field is required')
              : 'Please provide valid phone number.'
          "
          @blur="$v.phone.$touch()"
          @validate="isPhoneValid = $event"
        />
      </div>
      <SfButton
        v-if="!dateTimeSelectionShown"
        class="sf-button--full-width actions__button"
        @click="showDateTimeSelection"
      >
        Next
      </SfButton>
      <div class="time-details card" v-if="dateTimeSelectionShown">
        <SfHeading
          title="APPOINTMENT DATE AND TIME"
          :level="3"
          class="sf-heading--left sf-heading--no-underline"
        />
        <SfHeading
          title="Select Date"
          :level="4"
          class="sf-heading--left sf-heading--no-underline"
        />
        <div class="date-select__wrapper">
          <div class="date-select">
            <div
              v-for="date of getDays()"
              :key="date.fullDate"
              :class="[
                'date__wrapper',
                selectedDate && selectedDate.fullDate === date.fullDate
                  ? 'date--current'
                  : '',
              ]"
              @click="selectDate(date)"
            >
              <div class="date__month">{{ date.month }}</div>
              <div class="date__date">{{ date.date }}</div>
              <div class="date__day">{{ date.day }}</div>
            </div>
          </div>
        </div>
        <div
          class="no-slots-message"
          v-if="selectedDate && !hourOptions.length"
        >
          No Slots Available for this date.
        </div>
        <div
          class="time-select__wrapper"
          v-if="selectedDate && hourOptions.length"
        >
          <SfHeading
            title="Select Time"
            :level="4"
            class="sf-heading--left sf-heading--no-underline"
          />
          <div class="time-picker__wrapper">
            <div style="font-size: 24px">
              <div class="scroll-picker-labels" style="font-size: 18px">
                <div class="label">Hour</div>
                <div class="label">Minute</div>
              </div>
              <scroll-picker-group class="flex">
                <scroll-picker
                  :options="hourOptions"
                  :scroll-sensitivity="0.5"
                  v-model="hourSelection"
                ></scroll-picker>
                <scroll-picker
                  :options="minuteOptions"
                  v-model="minuteSelection"
                ></scroll-picker>
              </scroll-picker-group>
            </div>
          </div>
        </div>
      </div>
      <SfButton
        v-if="dateTimeSelectionShown"
        class="sf-button--full-width actions__button"
        :disabled="!isFormValid"
        @click="placeAppointmentOrder"
      >
        Confirm
      </SfButton>
    </div>
  </div>
</template>
<script>
import { SfInput, SfHeading, SfButton } from "@lib/components";
import PhoneInput from "@lib/components/atoms/PhoneInput.vue";
import { mapGetters, mapActions } from "vuex";
import { required, minLength, email } from "vuelidate/lib/validators";
import dayjs from "dayjs";
import { notifications } from "@lib/modules/cart/helpers";

const getSequence = (start, end) => {
  const sequence = [];
  for (let i = start; i <= end; i++) {
    const iString = i <= 9 ? "0" + i : "" + i;
    sequence.push(iString);
  }
  return sequence;
};

const getHour = (dayjsInstance) => {
  return Number(dayjsInstance.format("H"));
};

const getMinute = (dayjsInstance) => {
  return Number(dayjsInstance.format("m"));
};

export default {
  name: "Home",
  components: {
    SfInput,
    SfHeading,
    PhoneInput,
    SfButton,
  },
  data() {
    return {
      isPhoneValid: false,
      fullName: "",
      email: "",
      phone: {
        number: "",
        dialCode: "",
      },
      selectedDate: null,
      referenceNumber: null,
      hourSelection: null,
      minuteSelection: null,
      hourOptions: getSequence(0, 24),
      minuteOptions: getSequence(0, 60),
      dateTimeSelectionShown: false,
    };
  },
  validations() {
    return {
      fullName: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
        complex(value) {
          return this.isPhoneValid;
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      productsInCart: "cart/getCartItems",
      totals: "checkout/getTotals",
      isCartValid: "cart/isCartValid",
      store: "store",
      storeId: "storeId",
      isLoggedIn: "user/isLoggedIn",
      userPhoneNumber: "user/phoneNumber",
      userEmail: "user/email",
      userFullName: "user/displayName",
    }),
    _config() {
      return this.store.appointmentConfig;
    },
    slotDuration() {
      return this._config ? this._config.defaultDuration : null;
    },
    weekTimings() {
      if (
        !this._config ||
        !this._config.timings ||
        !this._config.timings.defaultWeek
      ) {
        return {};
      }
      return this._config.timings.defaultWeek;
    },
    isFormValid() {
      let isValid = true;
      if (this.$v.$invalid) {
        isValid = false;
      }
      if (!this.selectedDate || !this.minuteSelection || !this.hourSelection) {
        isValid = false;
      }

      if (!this.isCartValid) {
        isValid = false;
      }
      return isValid;
    },
  },
  watch: {
    isLoggedIn: {
      immediate: true,
      handler: function (isLoggedIn) {
        if (isLoggedIn) {
          this.fullName = this.userFullName;
          this.email = this.userEmail;
          this.phone.number = this.userPhoneNumber;
        }
      },
    },
    hourSelection: {
      immediate: true,
      handler: function (hour) {
        this.$nextTick(function () {
          this.minuteOptions = this.getMinuteOptions();
          if (
            this.minuteOptions.length &&
            this.minuteOptions.indexOf(this.minuteSelection) === -1
          ) {
            this.minuteSelection = this.minuteOptions[0];
          }
        });
      },
    },
  },
  methods: {
    getDays() {
      const startDate = dayjs();
      const endDate = dayjs(startDate).add(1, "months");
      var dateArray = [];
      var currentDate = dayjs(startDate);
      var stopDate = dayjs(endDate);
      while (currentDate <= stopDate) {
        const date = {
          fullDate: currentDate.format("LL"),
          day: currentDate.format("ddd"),
          date: currentDate.format("D"),
          month: currentDate.format("MMM"),
        };
        dateArray.push(date);
        currentDate = dayjs(currentDate).add(1, "days");
      }
      dateArray[0].day = "Today";
      dateArray[1].day = "Tomorrow";
      return dateArray;
    },
    selectDate(date) {
      this.selectedDate = date;
      this.hourOptions = this.getHourOptions();
      this.minuteOptions = this.getMinuteOptions();
    },
    getHourOptions() {
      const dayTimings = this.weekTimings[this.selectedDate.day.toLowerCase()];
      if (!dayTimings || dayTimings.disabled) {
        return [];
      }
      let selectedHour;
      if (this.hourSelection) {
        selectedHour = getHour(dayjs(this.hourSelection, "HH:mm"));
      }
      let startHour = 0;
      let endHour = 24;
      const dayStart = dayjs(dayTimings.start, "HH:mm");
      const dayEnd = dayjs(dayTimings.end, "HH:mm");
      const dayStartHour = getHour(dayStart);
      const dayEndHour = getHour(dayEnd);
      const dayEndMinute = getMinute(dayEnd);
      const now = dayjs();
      startHour = dayStartHour;
      endHour = dayEndHour;
      if (now.format("LL") == this.selectedDate.fullDate) {
        const currentHour = getHour(now);
        const currentMinute = getMinute(now);
        if (
          currentHour > dayEndHour ||
          (currentHour === dayEndHour && currentMinute > dayEndMinute)
        ) {
          return [];
        }
        if (currentHour > startHour) {
          startHour = currentHour;
        }
      }
      return getSequence(startHour, endHour);
    },
    getMinuteOptions() {
      if (!this.selectedDate) {
        return [];
      }
      const dayTimings = this.weekTimings[this.selectedDate.day.toLowerCase()];
      if (!dayTimings || dayTimings.disabled) {
        return [];
      }
      let selectedHour;
      if (this.hourSelection) {
        selectedHour = getHour(dayjs(this.hourSelection, "HH:mm"));
      }
      let startMinute = 0;
      let endMinute = 60;
      const dayStart = dayjs(dayTimings.start, "HH:mm");
      const dayEnd = dayjs(dayTimings.end, "HH:mm");
      const dayStartHour = getHour(dayStart);
      const dayEndHour = getHour(dayEnd);
      const now = dayjs();
      if (selectedHour === dayStartHour) {
        const dayStartMinute = getMinute(dayStart);
        startMinute = dayStartMinute;
      }
      if (selectedHour === dayEndHour) {
        const dayEndMinute = getMinute(dayEnd);
        endMinute = dayEndMinute;
      }
      if (now.format("LL") == this.selectedDate.fullDate) {
        const currentHour = getHour(now);
        const currentMinute = getMinute(now);
        if (selectedHour === currentHour) {
          if (startMinute < currentMinute) {
            startMinute = currentMinute;
          }
        }
      }
      return getSequence(startMinute, endMinute);
    },
    showDateTimeSelection() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.$store.dispatch(
          "notification/spawnNotification",
          notifications.createNotification({
            type: "danger",
            message: "Please enter the required details",
          })
        );
      } else {
        this.dateTimeSelectionShown = true;
        this.$nextTick(function () {
          const dateTimeSection = document.querySelector(".time-details");
          this.$smoothScroll({
            scrollTo: dateTimeSection,
          });
        });
      }
    },
    prepareOrder() {
      let order = {};
      order.personalDetails = {
        fullName: this.fullName,
        email: this.email,
        phone: this.phone ? this.phone.number : "",
        phoneDialCode: this.phone ? this.phone.dialCode : "",
      };
      order.type = "appointment";
      order.appointment = {
        fullName: this.fullName,
        email: this.email,
        phoneNumber: this.phone.number,
        phoneDialCode: this.phone.dialCode,
        appointmentDate: this.selectedDate.fullDate,
        appointmentTime: `${this.hourSelection}:${this.minuteSelection}`,
      };
      order.totals = this.totals.map((x) => ({
        code: x.code,
        amount: x.isFree ? 0 : x.value,
        isPercentage: x.isPercentage,
        percentAmount: x.percentAmount,
      }));
      order.cart = [];
      for (let product of this.productsInCart) {
        let cartItem = { ...product };
        delete cartItem.category;
        order.cart.push(cartItem);
      }
      if (this.isLoggedIn) {
        order.userInfo = {
          email: this.userEmail || null,
          phoneNumber: this.userPhoneNumber || null,
          fullName: this.userFullName || null,
        };
      }
      return { sfId: this.storeId, order };
    },
    async placeAppointmentOrder() {
      this.$store.commit("ui/setLoader", true);
      let order = this.prepareOrder();
      if (order) {
        try {
          const { orderId } = await this.$store.dispatch(
            "order/createOrder",
            order
          );
          this.$store.dispatch(
            "appointment/setCurrentAppointment",
            order.order.appointment
          );
          let successNotfication = notifications.createNotification({
            type: "success",
            message: `Order Placed`,
          });
          this.$store.dispatch(
            "notification/spawnNotification",
            successNotfication
          );
          this.orderComplete = true;
          this.$store.dispatch("cart/clear", { sync: true });
          this.$router.push({
            name: "appointmentSuccess",
          });
        } catch (error) {
          this.$store.dispatch(
            "notification/spawnNotification",
            notifications.createNotification({
              type: "danger",
              message:
                "Could not place your order. Please try again or contact store directly.",
            })
          );
        }
      }
      this.$store.commit("ui/setLoader", false);
    },
  },
};
</script>
<style lang="scss">
@import "~vue-scroll-picker/dist/style.css";
@import "~@lib/styles";
.appointment-details {
  margin-top: var(--spacer-base);
  box-sizing: border-box;
  padding: 0 var(--spacer-sm);
  --section-margin: var(--spacer-lg) 0;
  --section-content-margin: 0 0 var(--spacer-lg) 0;
  @include for-desktop {
    padding: 0 var(--spacer-sm);
    max-width: 1272px;
    margin: 0 auto;
    margin-top: 3rem;
    --section-margin: var(--spacer-lg) 0;
    --section-content-margin: 0 0 var(--spacer-lg) 0;
  }

  &__wrapper {
    margin-bottom: 5rem;
    .sf-button {
      border-radius: 0.375rem;
    }
  }

  .sf-heading {
    margin-bottom: var(--spacer-base);

    h2 {
      font-weight: 600;
    }

    h3 {
      font-size: var(--font-lg);
    }
  }

  .date-select {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    margin: 0 -1rem;
    margin-bottom: var(--spacer-base);
  }

  .slot-select {
    margin: 0 -1rem;
  }

  .time-picker__wrapper {
    max-width: 400px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 1rem 1.5rem;
    border-radius: 1rem;
    margin: 0 auto;
  }

  .date__wrapper {
    font-size: var(--font-base);
    padding: 0.25rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 66px;
  }

  .date__month,
  .date__day {
    font-size: var(--font-sm);
    padding: 0.375rem;
  }

  .date__date {
    border: 1px solid #888;
    border-radius: 50px;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    font-family: sans-serif;
    line-height: 2rem;
  }

  .date--current {
    .date__date {
      background: var(--c-primary);
      color: white;
    }
  }

  .no-slots-message {
    font-size: var(--font-sm);
    padding: 2rem 0;
    text-align: center;
  }

  .scroll-picker-labels {
    display: flex;

    .label {
      width: 100%;
      text-align: center;
    }
  }

  .card {
    //box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    //transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    color: rgba(0, 0, 0, 0.87);
    padding: 0.685rem 1.375rem;
    --radio-container-padding: var(--spacer-xs) 0;
    margin-bottom: 1.75rem;
    background: #fff;
  }

  .dine-in-space-selector {
    margin-top: 1rem;

    .sf-heading {
      padding-left: 15px;

      @include for-mobile {
        padding-left: 0;
      }
    }
  }

  .inline-group {
    overflow: hidden;
    padding-top: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-item {
      cursor: pointer;
      border-radius: 50px;
      float: left;
      margin: 0.25rem;
      position: relative;
      display: block;
      padding: 0.5rem 1rem;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.125);
      font-family: roboto;
      font-weight: 300;
      min-width: 20%;
      text-align: center;

      &.active {
        color: #fff;
        background-color: var(--c-primary);
        border-color: var(--c-primary);
      }

      &.disabled {
        color: #6c757d;
        pointer-events: none;
        background-color: #fff;
      }
    }
  }
}
</style>
